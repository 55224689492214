<template v-if="arrayRes">
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div v-if="arrayRes.name" class="two-rows-wrapper">
      <main>
        <section>
          <h1>{{arrayRes.name}}</h1>
          <filter-convocation-council v-if="false"/>
        </section>
        <tricolor-line class="tricolor"/>
        <div v-if="readyArrayMembers.length" class="main__title" id="nav_members">
          <header>
            <h2> {{ $store.getters.GET_LANG ? 'Состав' : 'Structure' }}</h2>
          </header>
          <MemberCommittee v-for="(item,i) in readyArrayMembers"
                           :key="`readyArrayMembers${i}`"
                           :title="item.title"
                           :members="item.units"
          />
        </div>
        <div v-if="arrayRes.working_groups.length" id="nav_working_groups" class="main__title">
          <header>
            <h2>{{ $store.getters.GET_LANG ? 'Рабочие группы' : 'Working groups' }}</h2>
          </header>
          <WorkingGroups :content="arrayRes.working_groups" />
        </div>
        <div v-if="$store.getters.getPublicNews.length !== 0" class="main__title" id="nav_news">
          <header>
            <h2>{{ $store.getters.GET_LANG ? 'Новости' : 'News' }}</h2>
          </header>
          <News @getPaginatedNews="getPaginatedNews()"/>
        </div>
        <div v-if="arrayRes.recommendation.length" id="nav_recommendation" class="main__title">
          <header>
            <h2>{{ $store.getters.GET_LANG ? 'Рекомендации' : 'Recommendations' }}</h2>
          </header>
          <documents-list v-for="(item, i) in arrayRes.recommendation"
                          :key="`list${i}`"
                          class="documents__list"
                          :doc="{ ...item, name: item.title, url: `${$store.getters.getUrlApi}${item.url}` }"
          />
        </div>
        <div v-if="arrayRes.documents.length && $store.getters.GET_LANG" id="nav_documents" class="main__title">
          <header>
            <h2 ref="titleSections">{{ $store.getters.GET_LANG ? 'Документы' : 'Documents' }}</h2>
          </header>
          <documents-list v-for="item in arrayRes.documents"
                          :key="`list${item.id}`"
                          class="documents__list"
                          :doc="{ ...item, url: `${$store.getters.getUrlApi}${item.url}` }"
          />
          <pagination v-if="documentsPagination.total !== 0" class="news__pagination"
                      :per-page="documentsPagination.limit"
                      :current="documentsPagination.currentPage"
                      :total="documentsPagination.total"
                      @page-changed="getPaginatedDocuments"/>
        </div>
      </main>
      <aside class="two-rows-navigation">
        <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
        <div v-for="(nav, i) in anchorNavigation" :key="i" >
          <a v-if="nav.life" :href="`#nav_${nav.code}`">{{ nav.title }}</a>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import News from '@/components/memberProfile/News'
// import Composition from '@/components/chamberCommittee/Composition'
import WorkingGroups from '@/components/chamberCommittee/WorkingGroups'
import FilterConvocationCouncil from '@/components/memberProfile/FilterConvocationCouncil'
import MemberCommittee from '@/components/chamberCommittee/MemberCommittee'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import uniq from '@/methods/uniq'
import Pagination from '@/components/Pagination'

export default {
  name: 'ChamberCommittee',
  props: {
    committeeId: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      convocation: null,
      documentsPagination: {
        offset: 0,
        limit: 10,
        total: 0,
        currentPage: 1
      },
      documentsOffset: 0,
      documentsLimit: 10,
      count: 0,
      titleActive: '1',
      activeElement: 1,
      showSelect: false,
      anchorNavigation: [],
      tagNews: [],
      arrayRes: {
        name: false
      },
      readyArrayMembers: []
    }
  },
  created () {
    this.getApiData()
  },
  beforeDestroy () {
    this.$store.commit('setFilterTag', null)
    this.$store.commit('setFilterWithActual', null)
  },
  methods: {
    getPaginatedNews () {
      this.$store.commit('setFilterSystemTagList', null)
      this.$store.commit('setFilterTag', this.arrayRes.name)
      this.$store.commit('setFilterWithActual', true)
      if (this.convocation) {
        this.$store.dispatch('getPublicNews', { convocationId: this.convocation })
      }
    },
    clickSelect () {
      this.showSelect = !this.showSelect
    },
    setInclusion (value) {
      console.log(value)
    },
    elementClick (event) {
      this.titleActive = event.title
      this.activeElement = event.id
      this.showSelect = !this.showSelect
    },
    async getApiData () {
      this.arrayRes = []
      this.tagNews = []
      this.readyArrayMembers = []
      let res = await fetch(`${this.$store.getters.getUrlApi}api/structure/${this.$route.params.id}?offset=${this.documentsPagination.offset}&limit=${this.documentsPagination.limit}`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.convocation = res?.data?.convocation
      this.arrayRes = await res.data
      this.documentsPagination.total = res.meta.total
      document.title = await res.data.name
      console.log(await this.arrayRes)
      await this.tagNews.push(res.data.name)
      await this.absMembers(res.data.members)
    },
    async getPaginatedDocuments (page) {
      this.documentsPagination.offset = (page - 1) * this.documentsPagination.limit
      this.documentsPagination.currentPage = page
      let res = await fetch(`${this.$store.getters.getUrlApi}api/structure/${this.$route.params.id}?offset=${this.documentsPagination.offset}&limit=${this.documentsPagination.limit}`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.arrayRes.documents = res.data.documents
    },
    getNews (tag) {
      this.$store.commit('setPaginationLimit', 3)
      // this.$store.dispatch('getAllCommitteeNews', tag.join(','))

      this.$store.commit('setFilterTag', this.arrayRes.name)
      this.$store.commit('setFilterSystemTagList', null)

      this.$store.commit('setFilterSystemTagList', 'all')
      // this.$store.dispatch('getAllCommitteeNews')
      this.$store.commit('setFilterTag', null)
    },
    absMembers (members) {
      let prepeareMembers = []
      members.map(item => {
        prepeareMembers.push('title' in item ? item.title : item.title_eng)
      })
      prepeareMembers = uniq(prepeareMembers)
      prepeareMembers.map((title, i) => {
        this.readyArrayMembers.push({ title, units: [] })
        members.map(member => {
          if (title === member.title || title === member.title_eng) {
            const tagName = member.fullName ? member.fullName.split(' ') : ''
            // console.log(member.fullName)
            // this.tagNews.push(member.fullName)
            this.tagNews.push(tagName[1] + ' ' + tagName[0])
            this.readyArrayMembers[i].units.push(member)
          }
        })
      })
      this.$nextTick(() => {
        this.createNav()
      })
    },
    createNav () {
      this.anchorNavigation = [
        {
          title: this.$store.getters.GET_LANG ? 'Состав' : 'Structure',
          life: this.readyArrayMembers.length,
          code: 'members'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Рабочие группы' : 'Working groups',
          life: this.arrayRes.working_groups.length,
          code: 'working_groups'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Новости' : 'News',
          life: this.$store.getters.getPublicNews.length, // 1,
          code: 'news'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Рекомендации' : 'Recommendations',
          life: this.arrayRes.recommendation.length,
          code: 'recommendation'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
          life: this.arrayRes.documents.length,
          code: 'documents'
        }
      ]
    }
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Структура общественной палаты' : 'The structure of the public chamber',
          path: '/structure_chamber'
        },
        {
          title: this.arrayRes.name ? this.arrayRes.name.slice(0, 100) + (this.arrayRes.name.length >= 100 ? '...' : '') : ''
        }
      ]
    }
  },
  watch: {
    arrayRes: {
      deep: true,
      handler () {
        this.$store.commit('setPaginationLimit', 3)
        this.arrayRes?.name && this.getPaginatedNews()
      }
    },
    '$route.params' (prev, next) {
      if (prev.id !== next.id) {
        this.getApiData()
      }
    },
    '$store.getters.GET_LANG' () {
      this.getApiData()
    },
    '$store.getters.getPublicNews' () {
      this.anchorNavigation[2].life = (this.$store.getters.getPublicNews.length !== 0) ? this.$store.getters.getPublicNews.length : 0
    }
  },
  components: {
    BreadCrumb,
    TricolorLine,
    News,
    Pagination,
    // Composition,
    WorkingGroups,
    FilterConvocationCouncil,
    MemberCommittee,
    DocumentsList
  }
}
</script>

<style scoped lang="scss">
  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: 1.375rem;
  }
  .documents__list {
    margin-bottom: 2rem;
  }
  .two-rows-navigation {
    a {
      display: block;
      margin-top: 1rem;
      color: #246CB7;
      line-height: 1.375;
      font-size: .875rem;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    color: rgba(31, 50, 69, 0.8);
    margin-bottom: 1.375rem;
  }

  .structure_select-param {
    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;
    padding: .5rem .75rem .56rem .75rem;
    background: none;
    background-size: 0;
    transition: background-size .35s ease;
  }
  .margin_select{
    margin-left: .625rem;
    padding-right: 0;
  }
  .structure_select-button{
    width: 1.25rem;
    height: 1.25rem;
    background: url('../../assets/icons/link.svg') center center no-repeat;
    background-size: cover;
    cursor: pointer;
    margin: 0;
  }
  .main__title {
    margin-top: 2rem;
  }
@media screen and (max-width: 1280px){
  .two-rows-wrapper{
    main{
      width: 67.27%;
      flex: none
    }
    //aside{
    //  display: none;
    //}
  }
  #nav_0, #nav_1, #nav_2, #nav_4, .tricolor, section{
    display: none;
  }
  .main__title{ margin: 0;}
}
@media screen and (max-width: 768px){
  .two-rows-wrapper{
    main{
      width: 100%;
    }
    .member-committee{
      overflow-x: scroll;
    }
  }
}
@media screen and (max-width: 420px){
  h2{
    margin-bottom: 1.88rem;
  }
}
</style>
